@tailwind base;

@tailwind components;

@tailwind utilities;

.mdc-icon-button img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  @apply !inline;
}

.mat-datepicker-toggle .mat-mdc-button-base {
  width: 48px !important;
  height: 48px !important;
}

.mdc-icon-button .mat-icon {
  vertical-align: top;
}

@font-face {
  font-family: Inter;
  src: url('assets/Inter.otf') format('opentype');
}

@font-face {
  font-family: Inter;
  src: url('assets/Inter-Italic.otf') format('opentype');
  font-style: italic;
}

@font-face {
  font-family: Inter;
  src: url('assets/Inter-Thin.otf') format('opentype');
  font-weight: 200;
}

@font-face {
  font-family: Inter;
  src: url('assets/Inter-ThinItalic.otf') format('opentype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: Inter;
  src: url('assets/Inter-Light.otf') format('opentype');
  font-weight: 300;
}

@font-face {
  font-family: Inter;
  src: url('assets/Inter-LightItalic.otf') format('opentype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: Inter;
  src: url('assets/Inter-Medium.otf') format('opentype');
  font-weight: 500;
}

@font-face {
  font-family: Inter;
  src: url('assets/Inter-MediumItalic.otf') format('opentype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: Inter;
  src: url('assets/Inter-SemiBold.otf') format('opentype');
  font-weight: 600;
}

@font-face {
  font-family: Inter;
  src: url('assets/Inter-SemiBoldItalic.otf') format('opentype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: Inter;
  src: url('assets/Inter-Bold.otf') format('opentype');
  font-weight: 700;
}

@font-face {
  font-family: Inter;
  src: url('assets/Inter-BoldItalic.otf') format('opentype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: Inter;
  src: url('assets/Inter-ExtraBold.otf') format('opentype');
  font-weight: 800;
}

@font-face {
  font-family: Inter;
  src: url('assets/Inter-ExtraBoldItalic.otf') format('opentype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: Inter;
  src: url('assets/Inter-Black.otf') format('opentype');
  font-weight: 900;
}

@font-face {
  font-family: Inter;
  src: url('assets/Inter-BlackItalic.otf') format('opentype');
  font-weight: 900;
  font-style: italic;
}

* {
  font-family: Inter, Roboto, serif;
  font-feature-settings: 'zero' 1, 'tnum' 1, 'ss01' 1 !important;
}

html {
  font-size: 14px;
  color: #12141d;
}

.card-with-shadow {
  @apply rounded shadow;
}

.card-with-shadow-white-no-radius {
    @apply shadow bg-white print:bg-white print:shadow-none;
}

.alternating-row-even {
    @apply even:bg-gray-100;
}

.alternating-row-odd {
    @apply odd:bg-gray-100;
}
