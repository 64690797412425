.mat-menu-panel {
  max-width: none !important;
}

.mat-menu-content {
  padding: 0 !important;
}

/* Reset the mat expansion panel to 0 margins */
.mat-expansion-panel-body,
.mat-expansion-panel-header {
  padding: 0 16px !important;
}

.mat-expansion-panel-header {
  height: 50px !important;
}

.mat-form-field-label {
  line-height: 16px !important;
}

.mat-tab-link {
  opacity: inherit !important;
  font-weight: 400 !important;
}

.mat-tab-nav-bar,
.mat-tab-header {
  border: 0;
}

.mdc-tab {
  @apply font-sans;
  letter-spacing: unset;
}

.mat-mdc-dialog-actions {
  @apply !justify-between;
}

.mat-ink-bar {
  height: 5px !important;
}

.mat-expansion-panel {
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
}

.mat-expansion-indicator {
  margin-left: 6px !important;
  margin-right: 6px !important;
}

.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float
  .mat-input-server:focus
  + .mat-form-field-label-wrapper
  .mat-form-field-label {
  transform: translateY(-1.4em) perspective(100px) scale(0.85) translateZ(0.001px);
  -ms-transform: translateY(-1.4em);
  width: 133.3333333333%;
}

.tooltip-overrides {
  max-width: initial !important;
  background: #ffffff !important;
  border: 1px solid #f7f8f8 !important;
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.05) !important;
  border-radius: 6px !important;
  color: black !important;
}

.mat-form-field-prefix {
  align-self: flex-end;
  margin-bottom: 2px;
}

.mat-select-value-text {
  line-height: 16px;
}

.mat-pseudo-checkbox {
  color: #95a2b3 !important;
  border-radius: 4px !important;
}

.mat-checkbox-frame {
  border-color: #95a2b3 !important;
  border-radius: 4px !important;
}

.mat-progress-bar-buffer {
  background-color: #eee;
}

.mat-progress-bar-fill::after {
  background-color: #776bff;
}

.mdc-linear-progress__bar-inner {
  border-top-width: 16px !important;
}

.gridline-path {
  stroke: #dadfe7;
}

.ngx-charts-tooltip-content {
  padding: 0 !important;
  color: inherit !important;
  background: initial !important;
}

mat-drawer.mat-drawer.ng-tns-c112-0.ng-trigger.ng-trigger-transform.mat-drawer-over.ng-star-inserted.mat-drawer-opened {
  transition: all 0.15s ease-in;
}

.cal-event-container {
  left: 0 !important;
  width: 100% !important;
}

.version-warning {
  transition: bottom 1s;
  z-index: 100000;
  bottom: -40px;
  width: 100%;
  position: fixed;
  display: flex;
  height: 40px;
  min-width: 100vw;
  max-width: 100vw;
  margin: 0;
  border-radius: 0px;
  background-color: #feb300;
  align-items: center;
  opacity: 0;
}

.wrong-version {
  bottom: 0;
  opacity: 1;
}

.version-warning-text {
  justify-content: center;
  line-height: 24px;
  text-align: center;
  color: black;
  width: 100%;
  font-size: 20px;
}

.snackbar-error .mat-simple-snackbar-action {
  color: white !important;
}
