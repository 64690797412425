@use '@angular/material' as mat;
@import '../node_modules/angular-calendar/css/angular-calendar.css';

$fontConfig: mat.define-typography-config(
  $font-family: 'Inter',
  $headline-1: mat.define-typography-level(112px, 112px, 300, 'Inter', -0.0134em),
  $headline-2: mat.define-typography-level(56px, 56px, 400, 'Inter', -0.0089em),
  $headline-3: mat.define-typography-level(45px, 48px, 400, 'Inter', 0em),
  $headline-4: mat.define-typography-level(34px, 40px, 400, 'Inter', 0.0074em),
  $headline-5: mat.define-typography-level(24px, 32px, 400, 'Inter', 0em),
  $headline-6: mat.define-typography-level(20px, 32px, 500, 'Inter', 0.0075em),
  $body-1: mat.define-typography-level(14px, 20px, 400, 'Inter', 0.0179em),
  $body-2: mat.define-typography-level(14px, 24px, 500, 'Inter', 0.0179em),
  $button: mat.define-typography-level(14px, 14px, 500, 'Inter', 0.0893em),
  $caption: mat.define-typography-level(12px, 20px, 400, 'Inter', 0.0333em),
  $overline: mat.define-typography-level(inherit, 1.125, 400, 'Inter', 1.5px),
);

// Theme Config

$dark-text: #000000;
$dark-primary-text: rgba($dark-text, 0.87);
$dark-accent-text: rgba($dark-primary-text, 0.54);
$dark-disabled-text: rgba($dark-primary-text, 0.38);
$dark-dividers: rgba($dark-primary-text, 0.12);
$dark-focused: rgba($dark-primary-text, 0.12);
$light-text: #ffffff;
$light-primary-text: $light-text;
$light-accent-text: rgba($light-primary-text, 0.7);
$light-disabled-text: rgba($light-primary-text, 0.5);
$light-dividers: rgba($light-primary-text, 0.12);
$light-focused: rgba($light-primary-text, 0.12);

body {
  --primary-color: #776bff;
  --primary-lighter-color: #d6d3ff;
  --primary-darker-color: #5a4eff;
}
$mat-primary: (
  main: #776bff,
  lighter: #d6d3ff,
  darker: #5a4eff,
  200: #776bff,
  // For slide toggle,
    contrast:
    (
      main: $light-primary-text,
      lighter: $dark-primary-text,
      darker: $light-primary-text,
    ),
);
$theme-primary: mat.define-palette($mat-primary, main, lighter, darker);

body {
  --accent-color: #425ec8;
  --accent-lighter-color: #c6cfef;
  --accent-darker-color: #2b42b4;
  --text-accent-color: #{$light-primary-text};
  --text-accent-lighter-color: #{$dark-primary-text};
  --text-accent-darker-color: #{$light-primary-text};
}
$mat-accent: (
  main: #425ec8,
  lighter: #c6cfef,
  darker: #2b42b4,
  200: #425ec8,
  // For slide toggle,
    contrast:
    (
      main: $light-primary-text,
      lighter: $dark-primary-text,
      darker: $light-primary-text,
    ),
);
$theme-accent: mat.define-palette($mat-accent, main, lighter, darker);

body {
  --warn-color: #cb1010;
  --warn-lighter-color: #efb7b7;
  --warn-darker-color: #b80909;
  --text-warn-color: #{$light-primary-text};
  --text-warn-lighter-color: #{$dark-primary-text};
  --text-warn-darker-color: #{$light-primary-text};
}
$mat-warn: (
  main: #cb1010,
  lighter: #efb7b7,
  darker: #b80909,
  200: #cb1010,
  // For slide toggle,
    contrast:
    (
      main: $light-primary-text,
      lighter: $dark-primary-text,
      darker: $light-primary-text,
    ),
);
$theme-warn: mat.define-palette($mat-warn, main, lighter, darker);

$my-custom-theme: mat.define-light-theme(
  (
    color: (
      primary: $theme-primary,
      accent: $theme-accent,
      warn: $theme-warn,
    ),
    typography: $fontConfig,
  )
);

@include mat.core();
@include mat.all-component-themes($my-custom-theme);
